@import url('@halp/css');

:root {
	--border-radius-md: 0.375rem;
	--border-radius-half: 0.5rem;
	--padding-half: 0.5rem;

	/* Typography */
	--font-family: inter, arial, helvetica, sans-serif;

	/* Colors */
	--primary-color-lightest: var(--color-violet-1);
	--primary-color-lighter: var(--color-violet-2);
	--primary-color-light: var(--color-violet-4);
	--primary-color: var(--color-violet-7);
	--primary-color-dark: var(--color-violet-11);

	/* Nav */
	--nav-color-text: var(--primary-color-lightest);
	--nav-color-background: var(--primary-color-dark);
	--nav-color-active: var(--color-violet-9);
	--nav-color-separator: var(--color-violet-3);
	--nav-disabled-color-text: var(--color-grey-5);

	/* Tab Menu */
	--tab-menu-hightlight-color-background: var(--primary-color-lighter);
	--tab-menu-underline-color-border: var(--color-violet-6);

	/* FormField */
	--form-field-border-radius: var(--border-radius-half);
	--formfield-border-color: var(--base-color-lightest);
	--formfield-hover-border-color: var(--color-violet-8);
	--formfield-focus-border-color: var(--color-violet-8);
	--formfield-hover-background-color: var(--color-grey-1);
	--formfield-hover-color: var(--color-violet-8);
	--formfield-focus-color: var(--color-grey-12);

	/* FormSelect */
	--formselect-focus-background-color: var(--color-violet-1);
	--formselect-color: var(--color-grey-9);

	/* Button */
	--button-primary-color: var(--color-violet-7);
	--button-primary-color-hover: var(--color-violet-6);
	--button-primary-color-active: var(--color-violet-8);
	--button-border-radius: var(--border-radius-md);

	/* Avatar Color Primary */
	--avatar-primary-color: var(--color-violet-10);
	--avatar-primary-background-color: var(--color-violet-2);

	/* Shell */
	--shell-sidenav-width: 15rem;
}
